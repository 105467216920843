import { h } from 'preact';
import { Router, route } from 'preact-router';
import xxx from '../index.scss';
import Match from 'preact-router/match';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Community from '../routes/community';
import Matches from '../routes/matches';
import Profile from '../routes/profile';
import Swipe from '../routes/swipe';
import Header from './navbar';

const users = [
    {
        id: "1",
        name: 'nihililix',
        url: 'https://p4.wallpaperbetter.com/wallpaper/985/452/866/archer-women-fantasy-art-depth-of-field-wallpaper-preview.jpg',
        moviematchids: ['1', '4']
    },
    {
        id: "2",
        name: 'Unicorn',
        url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTutsYmERSolFMN6xHP9H4WaoN34--epY8u3w&usqp=CAU',
        moviematchids: ['1', '2', '3']
    },
    {
        id: "3",
        name: 'StupidGuy',
        url: 'https://previews.123rf.com/images/studiostoks/studiostoks1507/studiostoks150700016/43321057-manly-man-astronaut-in-a-spacesuit-retro-style-star-traveller-sci-fi-space-adventure.jpg',
        moviematchids: ['1']
    },
    {
        id: "4",
        name: 'WonderBatman',
        url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJD2CeuFSx9TKoCVNXr8ufLqJxJrqx4LjbLw&usqp=CAU',
        moviematchids: ['1', '2']
    }
]

const movies = [
    {
        id: '1',
        name: 'Alien',
        date: '33. März 2021',
        duration: '129 Minuten',
        url: 'https://i0.wp.com/thinkmonsters.com/speakinghuman/media/wp-content/uploads/Alien-1979-Movie-Poster.jpg?resize=1080%2C1620&ssl=1'
    },
    {
        id: '2',
        name: 'The Heart of the Sea',
        date: '44. März 2021',
        duration: '129 Minuten',
        url: 'https://www.fubiz.net/wp-content/uploads/2015/02/movie-posters-twofive-03.jpg'
    },
    {
        id: '3',
        name: 'My Fair Lady',
        date: '55. März 2021',
        duration: '129 Minuten',
        url: 'https://miro.medium.com/max/1400/1*ya7Ewh-4n1_fgpGFpHmqMQ.jpeg'
    },
    {
        id: '4',
        name: 'Joker',
        date: '22. März 2021',
        duration: '129 Minuten',
        url: 'https://i.pinimg.com/564x/fe/e7/ea/fee7eab62f787cf7bbd3aa3cce3ac833.jpg'
    },
    {
        id: '5',
        name: 'Pans Labyrinth ',
        date: '11. März 2021',
        duration: '129 Minuten',
        url: 'https://i.etsystatic.com/6030519/r/il/cfe258/281644887/il_fullxfull.281644887.jpg'
    }
]

const matchlist = [
    {
        moviematchid: '1',
        usermatchid: ['1', '4']
    },
    {
        moviematchid: '3',
        usermatchid: ['3']
    },
    {
        moviematchid: '5',
        usermatchid: ['1', '2', '3', '4']
    },
]

const handleRoute = e => {
    if (e.url.slice(-1) !== '/') {
        route(`${e.url}/`, true);
    }
};

const App = () => (<>
    <link rel="preload" href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap" rel="stylesheet" />
    <link rel="preload" href="https://fonts.googleapis.com/css2?family=Material+Icons"rel="stylesheet" />

    <Match path="/">{({ matches }) => !matches &&  <Header />}</Match>

    <div class="wrapper">
        <div class="row">
            <div class="col col-xs-12">
                <div class="inner">
                    <Router  onChange={handleRoute}>
                        <Home path="/" default />
                        <Swipe path="/swipe" movies={movies} />
                        <Matches path="/matches" movies={movies} users={users} matchlist={matchlist} />
                        <Community path="/community" user="nihililix" users={users} movies={movies} />
                        <Profile path="/profile" user="nihililix" />
                        <Profile path="/profile/:user" />
                    </Router>
                </div>
            </div>
        </div>
    </div>
</>)

export default App;

import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.scss';

const Header = () => (
    <nav class={style.navbar}>
        <div class="wrapper">
            <div class="row">
                <div class='col-xs-3'>
                    <Link class={style.navbar__link} activeClassName={`${style['navbar__link--active']}`} href="/swipe">
                        <span class={`${style.navbar__icon} ${'material-icons'}`}>view_carousel</span>
                    </Link>
                </div>
                <div class='col-xs-3'>
                    <Link class={style.navbar__link} activeClassName={`${style['navbar__link--active']}`} href="/matches">
                        <span class={`${style.navbar__icon} ${'material-icons'}`}>movie</span>
                    </Link>
                </div>
                <div class='col-xs-3'>
                    <Link class={style.navbar__link} activeClassName={`${style['navbar__link--active']}`} href="/community">
                        <span class={`${style.navbar__icon} ${'material-icons'}`}>group</span>
                    </Link>
                </div>
                <div class='col-xs-3'>
                    <Link class={style.navbar__link} activeClassName={`${style['navbar__link--active']}`} href="/profile">
                        <span class={`${style.navbar__icon} ${'material-icons'}`}>face</span>
                    </Link>
                </div>
            </div>
        </div>
    </nav>
);

export default Header;
